// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bP_k5";
export var caseStudyBackground__lineColor = "bP_kX";
export var caseStudyHead__imageWrapper = "bP_kV";
export var caseStudyProjectsSection = "bP_k6";
export var caseStudyQuote__bgRing = "bP_k2";
export var caseStudySolution__ring = "bP_k0";
export var caseStudySolution__ringThree = "bP_l6";
export var caseStudySolution__ringTwo = "bP_k1";
export var caseStudyVideo__ring = "bP_k8";
export var caseStudy__bgDark = "bP_kT";
export var caseStudy__bgLight = "bP_kS";
export var caseStudy__bgLightGray = "bP_mY";